import Dashboard from "./pages/Dashboard";
import queryString from "query-string";

const App = () => {
	const parsed = queryString.parse(window.location.search);

	return <Dashboard {...parsed} />;
};

export { App };
