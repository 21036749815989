import { useEffect } from "react";
import Messages from "../../services/messages";

const TestPage = (parsed: any) => {
	const sendMessage = () => {
		Messages.sendMessageToApp({
			type: 'scanner',
			patientName: parsed.patientName??'TEST PATIENT NAME',
			caseId: parsed.caseId??(new Date().getTime().toString()),
			toothNumbers: parsed.toothNumbers??'1,2,3',
			toothSystem: parsed.toothSystem??'',
			workflow: parsed.workflow??'1',
			bucketName: parsed.bucketName??'',
			fileName: parsed.fileName??'',
			folderName: parsed.folderName??'',
		});
	};

	useEffect(()=>{
		Messages.sendMessageToAppsmith({
			active: true,
			status: 'test',
			message: 'App Escaner disponible'
		});
	},[]);

	return (
		<div>
			<button
				onClick={sendMessage}
				style={{
					backgroundColor: "#813EE0",
					color: "#FFFFFF",
					padding: "15px",
					fontSize: "14px",
					fontWeight: "bold",
					minWidth: "200px",
					margin: "1px",
					border: "none",
					borderRadius: "4px",
				}}
			>
				Probar escaner
			</button>
		</div>
	);
};

export default TestPage;