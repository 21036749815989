import { isFuturaApp } from "../utils/utils";
import { FuturaPage } from "./Futura/index";
import { ValidateFuturaPage } from "./ValidateFuturaPage/index";
import { NotAvailablePage } from "./NotAvailable/index";

const Views = {
	futura: FuturaPage,
	validateFutura: ValidateFuturaPage,
	not_available: NotAvailablePage,
};

const Dashboard = (parsed: any) => {
	const app = isFuturaApp()  ? (parsed['method'] && parsed['method']==='validate'? "validateFutura": "futura") : "not_available";
	const View =   Views[app];
	return <View {...parsed} />;
}

export default Dashboard;
