import { useEffect } from "react";
import Messages from "../../services/messages";

export const NotAvailablePage = (parsed: any) => {
	// const { message = "Escaner no disponible" } = parsed;
	useEffect(()=>{
		Messages.sendMessageToAppsmith({
			active: false,
			message: 'App de escaner no disponible'
		});
	},[]);
	return <div>{}</div>;
};
