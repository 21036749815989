interface Message {
	type: string;
}
interface ScannerMessage extends Message {
	caseId: string;
	patientName: string;
	toothNumbers: string;
	toothSystem: string;
	workflow: string;

	bucketName: string;
	fileName: string;
	folderName: string;
}

const Messages = () => {
	let messages: Array<any> = [];
	const sendMessageToApp = (parsed: Message | ScannerMessage) => {
		const obj = {
			direction: "sent",
			date: new Date(),
			...parsed,
			id: new Date().getTime(),
		};

		console.log("iFrameMiddleware.sendMessageToApp", obj);
		try {
			window.top?.postMessage(obj, "*");
		} catch (ex) {
			console.log("iFrameMiddleware.error", ex);
		}
	};

	const sendMessageToAppsmith = (data: any) => {
		console.log("iFrameMiddleware.sendMessageToAppsmith", data);
		window.parent?.postMessage({ ...data }, "*");
	};

	//setMessages((arr) => {
	const updateMessages = (newObject: any) => {
		const newArray = [newObject, ...messages];

		messages = newArray.filter((item, index) => {
			let exists = false;
			for (let x = index + 1; x < newArray.length; x++) {
				const msg = newArray[x];
				if (msg.id === item.id && msg.direction === item.direction) {
					exists = true;
					break;
				}
			}
			return !exists;
		});
	};

	const validateSending = (e: any) => {
		const id = e.data.id;
		let exists = messages.find((item: any) => item.message.id === id || item.id === id);

		if (id && id > 0 && !exists) {
			//} && e.data.direction!=='sent') {
			console.log("iFrameMiddleware.onMessage", e);

			const newObject = {
				type: "received",
				date: new Date(),
				message: e.data,
				id: id,
				origin: e.origin,
			};

			updateMessages(newObject);

			return true;
		}
		return false;
	};

	const listener = (e: any, callback: (arg0: Event) => void) => {
		if (validateSending(e)) {
			sendMessageToAppsmith({ ...e.data });
			callback(e);
		}
	};

	let listen: EventListenerOrEventListenerObject | null = null;

	const startListening = (callback: (arg0: Event) => void) => {
		console.log("iFrameMiddleware.startListening");
		listen = (event: Event) => {
			listener(event, callback);
		};
		window.addEventListener("message", listen);
		window.addEventListener("FuturaClientMessage", listen);
	};

	const stopListening = (callback: () => void) => {
		console.log("iFrameMiddleware.stopListening");
		if (listen) {
			window.removeEventListener("message", listen);
			window.removeEventListener("FuturaClientMessage", listen);
		}
		callback();
	};

	return {
		startListening,
		stopListening,

		sendMessageToAppsmith,
		sendMessageToApp,
	};
};

export default Messages();
