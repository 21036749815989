import { useEffect } from "react";
import Messages from "../../services/messages";

const DestroyedPage = (parsed: any) => {
	useEffect(()=>{
		Messages.sendMessageToAppsmith({
			active: true,
			status: 'test',
			message: 'App Escaner disponible'
		});
	},[]);
	return (
		<div>
			<button
				disabled={true}
				style={{
					backgroundColor: "#513EE0",
					color: "#FFFFFF",
					padding: "15px",
					fontSize: "14px",
					fontWeight: "bold",
					minWidth: "200px",
					margin: "1px",
					border: "none",
					borderRadius: "4px",
				}}
			>
				Desactivando
			</button>
		</div>
	);
};

export default DestroyedPage;