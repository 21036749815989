import { useEffect, useState } from "react";

import Messages from "../../services/messages";
import ConnectedPage from "./ConnectedPage";
import InitPage from "./InitPage";
import TestPage from "./TestPage";
import NotConnectedPage from "./NotConnectedPage";
import DestroyedPage from "./DestroyedPage";

const Views:any = {
	init: InitPage,
	connected: ConnectedPage,
	test: TestPage,
	not_connected: NotConnectedPage,
	destroyed: DestroyedPage,
};

export const FuturaPage = (parsed: any) => {
	const { test } = parsed;
	const [status, setStatus]: [string, any] = useState<string>("init");

	useEffect(() => {

		if(status === 'init') {
			Messages.startListening((message) => {
				console.log("OnMessageReceivedInCallback", message, test ? "test" : "connected");
				
			});
			setStatus(test ? "test" : "connected");
		}
		
	}, [status, test]);


	const View = Views[status];
	return <View {...parsed} />;
};
